import classNames from 'classnames';
import styles from './tip-card.module.scss';

/* eslint-disable-next-line */
export interface TipCardProps {
  title: string;
  children?: React.ReactNode;
}

export function TipCard(props: TipCardProps) {
  return (
    <div className={classNames(
      styles['container'],
      "flex flex-col gap-3 px-6 py-4 font-inter",
      "rounded-3xl"
    )}>
      <div className='w-full h-12 text-xl font-normal border-b border-[#767676]'>
        {props.title}
      </div>
      {props.children}
    </div>
  );
}

export default TipCard;
