import classNames from 'classnames';
import styles from './card-cb-gift-price.module.scss';
import Gift from './gift.png';

/* eslint-disable-next-line */
export interface CardCbGiftPriceProps {
  item_price_id: string;
  price: number;
  currency_code: string;
  name: string;
  description: string;
  self_description: string;
  monthly_price: number;
  monthly_price_full: number;
  saved_money_text: string;
  bill_text: string;
  tip: string;
  className?: string;
  on_buy?: (item_price_id: string) => void;
}

export function CardCbGiftPrice(props: CardCbGiftPriceProps) {
  return (
    <div className={classNames(
      styles['container'],
      "rounded-2xl",
      "w-72 p-6",
      "relative",
      "font-inter"
    )}>
      <img className='absolute left-4 -top-6 w-16 h-auto' src={Gift} alt="gift" />
      <div className='relative w-full h-8 flex justify-center items-center'>
        {props.tip ? (
          <div className={classNames(
            'absolute left-1/2 -top-4 transform -translate-x-1/2 px-2 h-8 rounded-full text-white-grey text-sm  uppercase whitespace-nowrap flex justify-center items-center font-medium',
            {
              "bg-[#4f4f4f]": !props.tip.toLowerCase().includes('best'),
              "bg-[#1D7000]": props.tip.toLowerCase().includes('best')
            },
          )}>{props.tip}</div>
        ) : (
          null
        )}
      </div>
      <div className='flex flex-col justify-center items-center gap-4'>
        <p className="w-full text-white-grey text-2xl font-semibold font-inter text-center">{props.name}</p>
        <p className="w-full text-secondary-grey text-xs font-light font-inter text-center break-words">{props.description}</p>
      </div>
      <div className='h-32 flex flex-col items-center mt-6'>
        <div className='grow shrink flex flex-col justify-center items-center gap-4'>
          <div className="flex items-baseline gap-1">
            {props.monthly_price_full > 0 && (
              <p className="text-secondary-grey text-3xl line-through font-inter">${new Intl.NumberFormat('en-US').format(props.monthly_price_full)}</p>
            )}
            <p className='text-white-grey text-5xl font-semibold'>${new Intl.NumberFormat('en-US').format(props.monthly_price)}</p>
          </div>
          {(props.monthly_price_full - props.monthly_price > 0) ? (
            <span className='h-8 px-4 bg-green text-white-grey uppercase flex justify-center items-center rounded-full'>
              Save ${props.monthly_price_full - props.monthly_price}
            </span>
          ) : null}
        </div>
        <p className='text-secondary-grey text-sm font-light'>One-time charge only</p>
      </div>
      <hr className="w-full border-t-0 border-b border-secondary-grey mt-4 mb-6" />
      <button className='w-full h-[52px] bg-primary-golden text-black rounded-full' onClick={() => props.on_buy?.(props.item_price_id)}>Buy a Gift</button>
      <p className='w-full text-secondary-grey text-sm font-light text-center mt-4'>Access all features</p>
    </div>
  );
}

export default CardCbGiftPrice;
