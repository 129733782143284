import { useState } from 'react';
import styles from './signup-email-form.module.scss';
import classNames from 'classnames';
import lodash from 'lodash';
import InputIcon from '../input-icon/input-icon';
import { IcEmail, IcIdentify, IcPassword, IcTicket1, IcUser } from '@clc-v2/icons';

/* eslint-disable-next-line */
export interface SignupEmailFormProps {
  signup_handler: (param: {
    given_name?: string,
    family_name?: string,
    user_name: string,
    picture: string,
    email: string,
    password: string,
    coupon?: string,
  }) => Promise<boolean>;
  // forgot_passwordHandler: () => void;
}

export function SignupEmailForm(props: SignupEmailFormProps) {
  const [loading, set_loading] = useState<boolean>(false);
  const [family_name, set_family_name] = useState<string>('');
  const [given_name, set_given_name] = useState<string>('');
  const [user_name, set_user_name] = useState<string>('');
  const [email, set_email] = useState<string>('');
  const [password, set_password] = useState<string>('');
  const [picture, set_picture] = useState<string>('');
  const [err_msgs, set_err_msgs] = useState<string[]>([]);
  const [show_coupon, set_show_coupon] = useState<boolean>(false);
  const [coupon, set_coupon] = useState<string>('');
  const signupHandler = lodash.debounce(() => {
    if (user_name && email && password) {
      set_loading(true);
      set_err_msgs([]);
      props.signup_handler({
        given_name,
        family_name,
        user_name,
        email,
        password,
        picture,
        coupon,
      }).then((value) => {
      }).catch((msgs: string[]) => {
        set_err_msgs(msgs);
      }).finally(() => {
        set_loading(false);
      });
    }
  }, 300);
  return (
    <div className={classNames(
      styles['container'],
      'flex flex-col gap-[10px]',
      'w-full',
    )} onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        signupHandler();
      }
    }}>
      <InputIcon
        prefix={<IcUser />}
        type="text"
        placeholder='First Name (Optional)'
        value={given_name}
        onChange={(e) => set_given_name(e.target.value)}
      />
      <InputIcon
        prefix={<IcIdentify />}
        type="text"
        placeholder='Last Name (Optional)'
        value={family_name}
        onChange={(e) => set_family_name(e.target.value)}
      />
      <InputIcon
        prefix={<IcEmail />}
        type="email"
        placeholder='Email'
        value={email}
        onChange={(e) => set_email(e.target.value)}
      />
      <InputIcon
        prefix={<IcIdentify />}
        type="text"
        placeholder='Username'
        value={user_name}
        onChange={(e) => set_user_name(e.target.value)}
      />
      <InputIcon
        prefix={<IcPassword />}
        type="password"
        placeholder='Password'
        value={password}
        onChange={(e) => set_password(e.target.value)}
      />
      {!show_coupon && <div className='flex justify-start items-center'>
        <span className='text-white font-inter cursor-pointer' onClick={() => set_show_coupon((_prev) => !_prev)}>{show_coupon ? "Hide Coupon" : "Do you have coupon?"}</span>
      </div>}
      {show_coupon && <InputIcon
        prefix={<IcTicket1 />}
        type="text"
        placeholder='Coupon Code'
        value={coupon}
        onChange={(e) => set_coupon(e.target.value)}
      />}
      {/* <div className='flex justify-center items-center'>
        <span className='text-white cursor-pointer' onClick={props.forgot_passwordHandler}>
          Forgot password?
        </span>
      </div> */}
      {
        err_msgs.length > 0 && (
          <div className='relative'>
            {err_msgs.slice(0, 3).map((msg, id) => <p className='text-text-op70' key={id}>{msg}</p>)}
          </div>
        )
      }
      <button className='w-full h-14 bg-black hover:bg-primary-golden transition-all border border-solid border-primary-golden text-white text-sm font-light rounded-full flex justify-center items-center tracking-widest' disabled={loading} onClick={signupHandler}>
        {loading ? "SIGNING UP" : "SIGN UP"}
      </button>
    </div>
  );
}

export default SignupEmailForm;
