import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom"
import { AES, enc } from "crypto-js";
import Cookies from "js-cookie";
import moment from "moment";
import { debounce } from "lodash";
import { useListener } from "react-bus";
import classNames from "classnames";
import { IntercomProvider } from 'react-use-intercom';
import ReactPixel from 'react-facebook-pixel';
import Modal from "react-responsive-modal";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { calcUntilAllowed, checkRole, expired, LOGIN_DAY1, LOGIN_DAY2, LOGIN_DAY3, LOGIN_DAY4, LOGIN_DAY5, LOGIN_DAY6, LOGIN_DAY7, parseJwt } from "@clc-v2/utilities";
import { ClcSpinner } from "@clc-v2/uis";
import { LibraryType } from "@prisma/client";
import { CACHE_TIME, NX_AUTH_REDIRECT_CACHE_TIME, NX_INTERCOM_ID, NX_PIXEL_ID } from "./config";
import { useAppDispatch, useAppSelector } from "./stores/hooks";
import { set_user, update_last_claimed_daily_20_questions, update_last_claimed_weekly_new_hands } from "./stores/auth";
import { set_count_daily_question, set_current_server_time, set_levels, set_prize_slice_texts } from "./stores/data";
import { set_cb_instance, set_subscription } from "./stores/payment";
import { set_glossaries } from "./stores/topic-lesson-question";
import { set_lifetime_point } from "./stores/performance";
import { set_new_hand_numbers_of_this_week, set_this_week_played_hand_numbers, set_today_played_ai_hand_numbers, set_unchecked_hand_numbers_this_week, set_unplayed_hand_numbers, set_unplayed_hand_numbers_this_week } from "./stores/dashboard";
import { accountService } from "./services/account.service";
import { SSEService } from "./services/sse.service";
import { leaderboardService } from "./services/leaderboard.service";
import { historyService } from "./services/history.service";
import api from "./services/_api";
import { pointSystemService } from "./services/point-system.service";
import { paymentService } from "./services/payment.service";
import { dataService } from "./services/data.service";
import { topicLessonQuestionService } from "./services/topic-lesson-question.service";
import { toast } from "react-toastify";
const Layout1 = lazy(() => import("./layouts/layout1"));
const Layout2 = lazy(() => import("./layouts/layout2"));
const Intro = lazy(() => import("./pages/intro"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const LearnByAI = lazy(() => import("./pages/learning/learn-by-ai"));
const PickYourPath = lazy(() => import("./pages/learning/pick-your-path"));
const LearnLesson = lazy(() => import("./pages/learning/learn-lesson"));
const MyPerformance = lazy(() => import("./pages/performance/my-performance"));
const LibraryListLayout = lazy(() => import("./pages/library/list/layout"));
const LibraryWebinarDetail = lazy(() => import("./pages/library/detail/webinar-detail"));
const LibraryLessonDetail = lazy(() => import("./pages/library/detail/lesson-detail"));
const LibraryListWebinars = lazy(() => import("./pages/library/list/webinars"));
const LibraryListLessons = lazy(() => import("./pages/library/list/lessons"));
const LibraryListNotFound = lazy(() => import("./pages/library/list/not-found"));
const LearnHistory = lazy(() => import("./pages/learning/learn-history").then((res) => ({ default: res.LearnHistory })));
const LibraryLayout = lazy(() => import("./pages/library/layout"));
const LibraryDetailLayout = lazy(() => import("./pages/library/detail/layout"));
const Account = lazy(() => import("./pages/account"));
const Payment = lazy(() => import("./pages/payment"));
const LearnByHandNumber = lazy(() => import("./pages/learning/learn-by-hand-number"));
const UnplayedNewHanded = lazy(() => import("./pages/learning/unplayed-new-handed"));
const LibraryListOnlineSessions = lazy(() => import("./pages/library/list/online-sessions"));
const LibraryOnlineSessionDetail = lazy(() => import("./pages/library/detail/online-session-detail"));
const LibraryListQuizzes = lazy(() => import("./pages/library/list/quizzes"))
const LibraryQuizDetail = lazy(() => import("./pages/library/detail/quiz-detail"));

api.defaults.headers.common['X-Timezone-Offset'] = new Date().getTimezoneOffset().toString();

const EncryptionKey = process.env.NX_AUTH_ENCRYPTION_KEY ?? '';
export const AuthCookieName = process.env.NX_AUTH_COOKIE_NAME ?? '';

const loginPath = process.env.NX_AUTH_DOMAIN ?? '' + '/auth/login';
export const loginUrl = `${loginPath}?redirect=${encodeURIComponent(window.location.href)}`;

const PrivateRoute = ({ role }: { role: number }) => {
    const user = useAppSelector((state) => state.auth.user);
    if (
        user?.role && checkRole(role, user?.role)
    ) {
        return <Outlet />
    } else {
        window.location.href = loginUrl;
    }
}

const ActiveUserRoute = () => {
    const { until_allowed } = useAppSelector((state) => state.payment);
    if (
        until_allowed !== undefined && !expired(until_allowed, 12 * 60) ||
        window.location.hostname === "localhost"
    ) {
        return <Outlet />
    } else {
        return <Navigate to={"/payment"} replace />
    }
}

export const AppRoutes = () => {
    const { user } = useAppSelector((state) => state.auth);
    const { prize_slice_texts_updated_at, levels_updated_at, count_daily_questions } = useAppSelector((state) => state.data);
    const { glossaries_updated_at } = useAppSelector((state) => state.topicLessonQuestion);
    const { subscription } = useAppSelector((state) => state.payment);
    const { today_played_ai_hand_numbers, today_played_ai_hand_numbers_updated_at, unplayed_hand_numbers_this_week, unplayed_hand_numbers_updated_at, new_hand_numbers_of_this_week, this_week_played_hand_numbers, this_week_played_hand_numbers_updated_at } = useAppSelector((state) => state.dashboard);
    const [loadingCookie, setLoadingCookie] = useState<boolean>(true);
    const [loading_subscription, set_loading_subscription] = useState<boolean>(true);
    const [sse_connected, set_sse_connected] = useState<boolean>(false);
    const [open_new_level_modal, set_new_level_modal] = useState<boolean>(false);
    const [new_level_message, set_new_level_message] = useState<string>('');
    const [open_master_lesson_modal, set_master_lesson_modal] = useState<boolean>(false);
    const [mastered_lesson, set_mastered_lesson] = useState<string>('');
    const [open_confetti, set_open_confetti] = useState<boolean>(false);
    const [earned_reward, set_earned_reward] = useState<string>('');
    const [modal_show_earned_reward, set_modal_show_earned_reward] = useState<boolean>(false);
    const [daily_reward, set_daily_reward] = useState<{ reward: number, day: string }>({ reward: 0, day: '' });
    const [modal_show_daily_reward, set_modal_show_daily_reward] = useState<boolean>(false);
    const [ai_modal_opened, set_ai_modal_opened] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const get_current_subscription = (first?: boolean) => {
        // if (!first) return
        paymentService.getCurrentSubscription().then((_subscription) => {
            dispatch(
                set_subscription(_subscription)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            if (first) {
                set_loading_subscription(false);
            }
        });
    }
    const claimDaily20QuestionsReward = debounce(() => {
        pointSystemService.claimDaily20QuestionsReward().then((data) => {
            dispatch(
                update_last_claimed_daily_20_questions(data)
            )
        }).catch((err_msg: string) => {
            console.log(err_msg);
        });
    }, 1000);
    const claimWeeklyNewHands = debounce(() => {
        pointSystemService.claimWeeklyNewHandsReward().then((data) => {
            dispatch(
                update_last_claimed_weekly_new_hands(data)
            )
        }).catch((err_msg: string) => {
            console.log(err_msg);
        });
    }, 1000);
    const aiModalOpenedHandler = (_ai_modal_opened: any) => {
        set_ai_modal_opened(_ai_modal_opened as boolean);
    }
    useListener('ai-continue-modal', aiModalOpenedHandler);
    useEffect(() => {
        const cookieAuthData = Cookies.get(AuthCookieName);
        if (cookieAuthData) {
            const bytes = AES.decrypt(cookieAuthData, EncryptionKey);
            const accessToken = bytes.toString(enc.Utf8);
            const { user_id, exp, iat, last_login_at } = parseJwt(accessToken);
            if (moment.unix(exp).diff(moment(), 'seconds') > 0) {
                // token is valid
                api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                accountService.selfAccount(user_id).then((_user) => {
                    dispatch(
                        set_user(_user)
                    )
                }).catch((err_msg: string) => {
                    setTimeout(() => {
                        window.location.href = loginUrl;
                    }, NX_AUTH_REDIRECT_CACHE_TIME);
                }).finally(() => {
                    setTimeout(() => {
                        setLoadingCookie(false);
                    }, NX_AUTH_REDIRECT_CACHE_TIME);
                });
            } else {
                // token is expired
                setTimeout(() => {
                    window.location.href = loginUrl;
                }, NX_AUTH_REDIRECT_CACHE_TIME);
            }
        } else {
            setTimeout(() => {
                window.location.href = loginUrl;
            }, NX_AUTH_REDIRECT_CACHE_TIME);
        }
    }, []);
    useEffect(() => {
        let timer: NodeJS.Timer | null = null;
        if (user?.id) {
            get_current_subscription(true);
            timer = setInterval(() => get_current_subscription(false), 15000);
            // SSE
            const sseService = new SSEService(user.id);
            sseService.subscribeToMasterLessonEvent((_mastered_lesson) => {
                set_open_confetti(true);
                setTimeout(() => {
                    set_open_confetti(false);
                }, 5000);
                set_master_lesson_modal(true);
                set_mastered_lesson(_mastered_lesson.name);
            });
            sseService.subscribeToEarnDailyRewardEvent((_reward) => {
                // if (lifetime_point) {
                //     dispatch(
                //         set_lifetime_point({
                //             ...lifetime_point,
                //             chips: lifetime_point.chips + _reward.reward
                //         })
                //     )
                // }
                set_modal_show_daily_reward(true);
                set_daily_reward({
                    reward: _reward.reward,
                    day: _reward.type === LOGIN_DAY1 ? 'Day 1'
                        : _reward.type === LOGIN_DAY2 ? 'Day 2'
                            : _reward.type === LOGIN_DAY3 ? 'Day 3'
                                : _reward.type === LOGIN_DAY4 ? 'Day 4'
                                    : _reward.type === LOGIN_DAY5 ? 'Day 5'
                                        : _reward.type === LOGIN_DAY6 ? 'Day 6'
                                            : _reward.type === LOGIN_DAY7 ? 'Day 7'
                                                : ''
                });
                leaderboardService.selfLifetimePoints().then((_lifetime_point) => {
                    dispatch(
                        set_lifetime_point(_lifetime_point)
                    )
                }).catch((err_msg: string) => {

                }).finally(() => {

                });
                // toast.success(<div>You earned <b>{_reward.reward}</b> chips by daily login</div>, {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "dark",
                // });
            });
            /**
             * Daily 20 Questions, Weekly New Hands, Watch Video
             */
            sseService.subscribeToExtraRewardEvent((_message) => {
                set_earned_reward(`${_message}`);
                set_modal_show_earned_reward(true);
                setTimeout(() => {
                    set_modal_show_earned_reward(false);
                }, 5000);
                // toast.success(<b>{_message}</b>, {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "dark",
                // });
                leaderboardService.selfLifetimePoints().then((_lifetime_point) => {
                    dispatch(
                        set_lifetime_point(_lifetime_point)
                    )
                }).catch((err_msg: string) => {

                }).finally(() => {

                });
            });
            /**
             * New level
             */
            sseService.subscribeToNewLevelEvent((_message) => {
                set_new_level_message(_message);
                if (!_message.startsWith('You are now')) {
                    set_open_confetti(true);
                }
                set_new_level_modal(true);
                setTimeout(() => {
                    // if (_message.startsWith('Congratulations')) {
                    set_open_confetti(false);
                    // }
                }, 5000);
            });
            sseService.subscribeToPlaqueEvent((_message) => {
                toast.success(`You earned ${_message} plaques`);
            });
            set_sse_connected(true);
            return () => {
                sseService.closeConnection();
            }
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        }
    }, [user?.id]);
    useEffect(() => {
        if (window.Chargebee && window.Chargebee.init) {
            var cbInstance = window.Chargebee.init({
                site: process.env.NX_CB_SITE, // your test site
                // publishableKey: Api_Key
            });
            cbInstance.setPortalSession(() => {
                return paymentService.manageSubscription()
            });
            dispatch(
                set_cb_instance(cbInstance)
            )
        }
    }, []);
    useEffect(() => {
        if (prize_slice_texts_updated_at === undefined || moment(prize_slice_texts_updated_at).diff(moment(), 'minutes') < CACHE_TIME) {
            dataService.getPrizeSliceTexts().then((_texts) => {
                dispatch(
                    set_prize_slice_texts(_texts)
                );
            }).catch((err_msg: string) => {
                console.error(err_msg)
            });
        }
    }, [prize_slice_texts_updated_at]);
    useEffect(() => {
        if (glossaries_updated_at === undefined || moment(glossaries_updated_at).diff(moment(), 'minute') < CACHE_TIME) {
            topicLessonQuestionService.getGlossaries().then((_glossaries) => {
                dispatch(
                    set_glossaries(_glossaries)
                )
            }).catch((err_msg: string) => {
                console.error(err_msg);
            });
        }
    }, [glossaries_updated_at]);
    useEffect(() => {
        dataService.getCurrentServerTime().then((current_time) => {
            dispatch(set_current_server_time(moment(current_time).unix()))
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
        dataService.getCountDailyQuestions().then((count_daily_questions) => {
            dispatch(set_count_daily_question(count_daily_questions))
        }).catch((err_msg: string) => {
            console.error(err_msg)
        })
    }, []);
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactPixel.init(NX_PIXEL_ID, undefined, {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: false // enable logs
            });
        }
    }, []);
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactPixel.pageView();
        }
    }, [location.pathname]);
    /**
     * Earn reward for completing AI daily questions
     */
    useEffect(() => {
        if (today_played_ai_hand_numbers_updated_at === undefined || expired(today_played_ai_hand_numbers_updated_at, 0)) {
            historyService.getEarningHistory(
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
                'ai'
            ).then((today_history) => {
                dispatch(
                    set_today_played_ai_hand_numbers(today_history.map(({ hand_number }) => hand_number))
                );
            }).catch((err_msg: string) => {
                // set_error(err_msg);
            });
        }
    }, [today_played_ai_hand_numbers_updated_at]);
    useEffect(() => {
        if (subscription && !expired(calcUntilAllowed(subscription), 0)) {
            if (today_played_ai_hand_numbers !== undefined) {
                const remain_cnt_of_questions_for_today = Math.max((count_daily_questions ?? parseInt(process.env.NX_DAILY_QUESTIONS ?? '0')) - (today_played_ai_hand_numbers ?? []).length, 0);
                if (remain_cnt_of_questions_for_today === 0 && user && (
                    user?.user_last_claimed_daily_20_questions === null || (
                        user?.user_last_claimed_daily_20_questions && moment(user.user_last_claimed_daily_20_questions).format('YYYY-MM-DD') !== moment().local().format('YYYY-MM-DD')
                    ))
                ) {
                    claimDaily20QuestionsReward();
                }
            }
        }
    }, [user?.last_claimed_daily_20_questions, today_played_ai_hand_numbers, subscription]);
    /**
     * Earn reward for completing Weekly New Hands
     */
    useEffect(() => {
        if (this_week_played_hand_numbers_updated_at === undefined || expired(this_week_played_hand_numbers_updated_at, 0)) {
            historyService.getEarningHistory(
                moment().startOf('isoWeek').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ).then((this_week_history) => {
                dispatch(
                    set_this_week_played_hand_numbers(this_week_history.map(({ hand_number }) => hand_number))
                );
            }).catch((err_msg: string) => {
                // set_error(err_msg);
            });
        }
    }, [this_week_played_hand_numbers_updated_at]);
    useEffect(() => {
        if (unplayed_hand_numbers_updated_at === undefined || expired(unplayed_hand_numbers_updated_at, 0)) {
            topicLessonQuestionService.getNewHandedNumbers().then((_new_handed_questions) => {
                dispatch(
                    set_unplayed_hand_numbers(_new_handed_questions.map(({ hand_number }) => hand_number))
                )
                const latest_date = _new_handed_questions.reduce((latest, current) => {
                    if (!latest || current.new_handed_at > latest.new_handed_at) {
                        return current;
                    }
                    return latest;
                }, {
                    hand_number: -1,
                    new_handed_at: ''
                });
                if (user?.last_checked_at_new_hands && moment(user.last_checked_at_new_hands).diff(moment(latest_date.new_handed_at), 'minutes') > 0) {
                    dispatch(
                        set_unchecked_hand_numbers_this_week([])
                    )
                } else {
                    const new_handed_last_week = _new_handed_questions.filter(
                        (hand) => hand.new_handed_at === latest_date.new_handed_at
                    );
                    dispatch(
                        set_unchecked_hand_numbers_this_week(new_handed_last_week.map(({ hand_number }) => hand_number))
                    )
                }
                const _unplayed_hand_numbers_this_week = _new_handed_questions.filter((_question) => moment(_question.new_handed_at).diff(moment().startOf('isoWeek'), 'minutes') > 0).map(({ hand_number }) => hand_number)
                dispatch(
                    set_unplayed_hand_numbers_this_week(_unplayed_hand_numbers_this_week)
                )
            }).catch((err_msg: string) => {
                // set_error_new_handed(err_msg);
            });
            topicLessonQuestionService.getNewHandedNumbersOfThisWeek().then((_new_handed_questions_of_this_week) => {
                dispatch(
                    set_new_hand_numbers_of_this_week(_new_handed_questions_of_this_week.map(({ hand_number }) => hand_number))
                )
            }).catch((err_msg: string) => {
                // set_error_new_handed(err_msg);
            });
        }
    }, [unplayed_hand_numbers_updated_at, user?.last_checked_at_new_hands]);
    useEffect(() => {
        if (subscription && !expired(calcUntilAllowed(subscription), 0)) {
            if (unplayed_hand_numbers_this_week !== undefined && new_hand_numbers_of_this_week !== undefined && this_week_played_hand_numbers !== undefined) {
                if (unplayed_hand_numbers_this_week.length === 0 && new_hand_numbers_of_this_week.length > 0 && new_hand_numbers_of_this_week.filter((hand_number) => this_week_played_hand_numbers.includes(hand_number)).length === new_hand_numbers_of_this_week.length && user && (
                    user?.user_last_claimed_weekly_new_hands === null || (
                        user?.user_last_claimed_weekly_new_hands && moment(user.user_last_claimed_weekly_new_hands).format('YYYY-MM-DD') !== moment().local().startOf('isoWeek').format('YYYY-MM-DD')
                    )
                )) {
                    claimWeeklyNewHands();
                }
            }
        }
    }, [user?.user_last_claimed_weekly_new_hands, unplayed_hand_numbers_this_week, new_hand_numbers_of_this_week, this_week_played_hand_numbers, subscription]);
    useEffect(() => {
        if (levels_updated_at === undefined || expired(levels_updated_at, CACHE_TIME)) {
            pointSystemService.getLevels().then((_levels) => {
                dispatch(
                    set_levels(_levels)
                )
            }).catch((err_msg: string) => {
                console.error(err_msg)
            })
        }
    }, [levels_updated_at]);
    const [imgBitmap, setImgBitmap] = useState<ImageBitmap>();
    useEffect(() => {
        (async () => {
            var image = new Image();
            image.src = '/assets/confetti-particle.png';
            image.onload = () => {
                createImageBitmap(image)
                    .then(bitmap => {
                        // use the bitmap here
                        setImgBitmap(bitmap);
                    })
                    .catch(err => {
                        console.error('Error creating image bitmap:', err);
                    });
            };
        })();
    }, []);
    if (loadingCookie || loading_subscription || sse_connected === false) {
        return (
            <div className="bg-primary-carbon w-scree h-screen flex justify-center items-center">
                <ClcSpinner text="Loading" />
            </div>
        )
    } else {
        return (
            <>
                <IntercomProvider
                    appId={NX_INTERCOM_ID}
                    autoBoot
                    autoBootProps={user ? {
                        name: user.user_name,
                        email: user.email,
                        userId: user.id,
                        customAttributes: { custom_attribute_key: 'Hi There!' }
                    } : undefined}
                >
                    <Routes>
                        <Route element={<PrivateRoute role={1} />}>
                            <Route
                                path="/"
                                element={
                                    <Suspense fallback={<></>}>
                                        <Layout1 />
                                    </Suspense>
                                }
                            >
                                <Route path="payment" element={
                                    <Suspense fallback={<></>}>
                                        <Payment />
                                    </Suspense>
                                } />
                                <Route path="" element={(
                                    subscription && !expired(calcUntilAllowed(subscription), 0)
                                ) ? <Navigate to={user?.intro_seen_at ? "/dashboard" : "/intro"} /> : <Navigate to="/payment" replace />} />
                            </Route>
                            <Route element={<ActiveUserRoute />}>
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<></>}>
                                            <Layout2 />
                                        </Suspense>
                                    }
                                >
                                    <Route element={<PrivateRoute role={1} />}>
                                        <Route
                                            path={"/intro"}
                                            element={
                                                <Suspense fallback={<></>}>
                                                    <Intro />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="dashboard"
                                            element={
                                                <Suspense fallback={<></>}>
                                                    <Dashboard />
                                                </Suspense>
                                            }
                                        />
                                        <Route
                                            path="learning"
                                            element={<Outlet />}
                                        >
                                            <Route path="pick-your-path" element={
                                                <Suspense fallback={<></>}>
                                                    <PickYourPath />
                                                </Suspense>
                                            } />
                                            <Route path="learn-by-lesson" element={
                                                <Suspense fallback={<></>}>
                                                    <LearnLesson />
                                                </Suspense>
                                            } />
                                            <Route path="learn-by-ai" element={
                                                <Suspense fallback={<></>}>
                                                    <LearnByAI />
                                                </Suspense>
                                            } />
                                            <Route path="learn-by-hand-number" element={
                                                <Suspense fallback={<></>}>
                                                    <LearnByHandNumber />
                                                </Suspense>
                                            } />
                                            <Route path="unplayed-new-handed" element={
                                                <Suspense fallback={<></>}>
                                                    <UnplayedNewHanded />
                                                </Suspense>
                                            } />
                                            <Route path="history" element={
                                                <Suspense fallback={<></>}>
                                                    <LearnHistory />
                                                </Suspense>
                                            } />
                                        </Route>
                                        <Route
                                            path="performance"
                                            element={<Outlet />}
                                        >
                                            <Route path="me" element={
                                                <Suspense fallback={<></>}>
                                                    <MyPerformance />
                                                </Suspense>
                                            } />
                                        </Route>
                                        <Route
                                            path="library"
                                            element={
                                                <Suspense fallback={<></>}>
                                                    <LibraryLayout />
                                                </Suspense>
                                            }
                                        >
                                            <Route
                                                path="list"
                                                element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListLayout />
                                                    </Suspense>
                                                }
                                            >
                                                <Route path="webinars" element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListWebinars />
                                                    </Suspense>
                                                } />
                                                <Route path="lessons" element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListLessons />
                                                    </Suspense>
                                                } />
                                                <Route path="online-sessions" element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListOnlineSessions />
                                                    </Suspense>
                                                } />
                                                <Route path="quizzes" element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListQuizzes />
                                                    </Suspense>
                                                } />
                                                <Route path="*" element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryListNotFound />
                                                    </Suspense>
                                                } />
                                            </Route>
                                            <Route
                                                path="detail"
                                                element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryDetailLayout />
                                                    </Suspense>
                                                }
                                            >
                                                <Route path={LibraryType.webinar} element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryWebinarDetail />
                                                    </Suspense>
                                                } />
                                                <Route path={LibraryType.lesson} element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryLessonDetail />
                                                    </Suspense>
                                                } />
                                                <Route path={LibraryType.online_session} element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryOnlineSessionDetail />
                                                    </Suspense>
                                                } />
                                                <Route path={LibraryType.quiz} element={
                                                    <Suspense fallback={<></>}>
                                                        <LibraryQuizDetail />
                                                    </Suspense>
                                                } />
                                            </Route>
                                        </Route>
                                        <Route path="account" element={
                                            <Suspense fallback={<></>}>
                                                <Account />
                                            </Suspense>
                                        } />
                                        <Route path="*" element={<Navigate to="/dashboard" />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        {/* <Route path="/discord-oauth" element={<DiscordCallback />} /> */}
                    </Routes>
                </IntercomProvider>

                {open_confetti && imgBitmap && (
                    <Fireworks autorun={{ speed: 3, duration: 5000 }} decorateOptions={(param) => {
                        return ({
                            ...param,
                            particleCount: 50,
                            shapes: [{
                                type: "bitmap",
                                bitmap: imgBitmap,
                                matrix: new DOMMatrix().scale(0.2),
                            }]
                        })
                    }} />
                )}
                <Modal classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden bg-primary-golden"
                }} center open={open_new_level_modal} onClose={() => {
                    set_new_level_modal(false);
                    set_new_level_message('');
                }} showCloseIcon={false}>
                    <p className="text-white text-3xl font-inter" dangerouslySetInnerHTML={{ __html: new_level_message }} />
                </Modal>
                <Modal classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden bg-primary-golden"
                }} center open={open_master_lesson_modal} onClose={() => set_master_lesson_modal(false)} showCloseIcon={false}>
                    <p className="text-white text-3xl font-inter">Congratulations! You have mastered <b>{mastered_lesson}</b>!</p>
                </Modal>
                <Modal classNames={{
                    modal: "!bg-primary-carbon border border-primary-golden bg-primary-golden"
                }} center open={modal_show_daily_reward} onClose={() => set_modal_show_daily_reward(false)} showCloseIcon={false}>
                    <p className="text-white text-3xl font-inter"><b>+{daily_reward.reward}</b> XP for logging in on {daily_reward.day}</p>
                </Modal>
                <Modal classNames={{
                    modal: "bg-transparent"
                }} center open={modal_show_earned_reward} onClose={() => set_modal_show_earned_reward(false)} showCloseIcon={false}>
                    <p className={classNames(
                        "text-primary-golden text-3xl animate-zoomIn",
                        {
                            "mb-80": ai_modal_opened
                        }
                    )}>{earned_reward}</p>
                </Modal>
            </>
        )
    }
}